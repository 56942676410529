import { createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';
import { createAlertAsync, handleErrorAlertAsync } from './alertsSlice';
import { verifyAuthAsync } from './authSlice';

const initialState = {
	contactnumbers: null,
	editing: null,
};

export const contactNumberSlice = createSlice({
	name: 'contactnumber',
	initialState: initialState,
	reducers: {
		createContactNumber: (state, action) => {
			state.contactnumbers.push(action.payload);

			state.contactnumbers = state.contactnumbers.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.contactnumbers = state.contactnumbers.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA < deptB) {
					return -1;
				}
				if (deptB > deptA) {
					return 1;
				}

				return 0;
			});
		},
		getContactNumber: (state, action) => {
			state.editing = action.payload;
		},
		getContactNumbers: (state, action) => {
			state.contactnumbers = action.payload;

			state.contactnumbers = state.contactnumbers.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.contactnumbers = state.contactnumbers.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});
		},
		updateContactNumber: (state, action) => {
			const contactnumberIndex = state.contactnumbers.findIndex(
				(dept) => dept._id === action.payload._id
			);

			state.contactnumbers[contactnumberIndex] = action.payload;

			state.contactnumbers = state.contactnumbers.sort((a, b) => {
				const nameA = a.Name.toUpperCase();
				const nameB = b.Name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			state.contactnumbers = state.contactnumbers.sort((a, b) => {
				const deptA = a.IsActive;
				const deptB = b.IsActive;
				if (deptA > deptB) {
					return -1;
				}
				if (deptB < deptA) {
					return 1;
				}

				return 0;
			});

			state.editing = action.payload;
		},
		removeContactNumbers: (state, action) => {
			state.contactnumbers = null;
		},
		setEditing: (state, action) => {
			let value = action.payload.value;
			if (action.payload.name === 'ValidNumber') {
				value = action.payload.checked;
			}

			state.editing = {
				...state.editing,
				[action.payload.name]: value,
			};
		},
		removeContactNumber: (state, action) => {
			state.editing = null;
		},
		resetContactNumbers: () => initialState,
	},
});

export const {
	createContactNumber,
	getContactNumber,
	getContactNumbers,
	updateContactNumber,
	removeContactNumbers,
	setEditing,
	removeContactNumber,
	resetContactNumbers,
} = contactNumberSlice.actions;

// Selectors
export const selectContactNumbers = (state) =>
	state.contactnumber.contactnumbers;

export const selectContactNumber = () => (state) => {
	if (state.contactnumber.contactnumbers && state.contactnumber.editing) {
		return state.contactnumber.contactnumbers.find(
			(contactnumber) => contactnumber._id === state.contactnumber.editing._id
		);
	} else {
		return null;
	}
};

export const selectEditing = () => (state) => {
	return state.contactnumber.editing;
};

// Create ContactNumbers Async
export const createContactNumberAsync =
	(name, validNumber) => async (dispatch) => {
		await api
			.post(
				`/admin/contactnumber`,
				{ Name: name, ValidNumber: validNumber },
				{ withCredentials: true }
			)
			.then((res) => {
				dispatch(createContactNumber(res.data));
			})
			.catch((error) => {
				dispatch(handleErrorAlertAsync(error));
			});
	};

// Get ContactNumbers Async
export const getContactNumbersAsync = () => async (dispatch) => {
	await api
		.get(`/admin/contactnumber`, { withCredentials: true })
		.then((res) => {
			dispatch(getContactNumbers(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Get ContactNumber Async
export const getContactNumberAsync = (id) => async (dispatch) => {
	await api
		.get(`/admin/contactnumber/${id}`, { withCredentials: true })
		.then((res) => {
			dispatch(getContactNumber(res.data));
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
			dispatch(verifyAuthAsync(error.response.data.message));
		});
};

// Update ContactNumber Async
export const updateContactNumberAsync = (contactnumber) => async (dispatch) => {
	await api
		.put(`/admin/contactnumber/${contactnumber._id}`, contactnumber, {
			withCredentials: true,
		})
		.then((res) => {
			dispatch(updateContactNumber(res.data));
			dispatch(
				createAlertAsync({
					message: 'ContactNumber Updated Successfully',
					severity: 'success',
					autoDismiss: true,
					timeout: 6000,
				})
			);
		})
		.catch((error) => {
			dispatch(handleErrorAlertAsync(error));
		});
};

export default contactNumberSlice.reducer;
