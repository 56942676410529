import React, { useState } from 'react';
import { blueGrey, green, orange, red } from '@mui/material/colors';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
	Box,
	TextField,
	Grid,
	MenuItem,
	IconButton,
	CircularProgress,
	ListItemText,
	FormControl,
	Select,
	InputLabel,
	Checkbox,
	Input,
	Modal,
	Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { gridStyles, gridItemStyles } from '../../../ComponentStyles';
import {
	addAccountContactNumberAsync,
	deleteContactNumberAsync,
} from '../../../../../redux/accountSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AddCircle, DeleteForever } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import _ from 'lodash';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { createAlertAsync } from '../../../../../redux/alertsSlice';
import CreatedModified from '../../../CreatedModified';
import { selectContactNumbers } from '../../../../../redux/contactNumberSlice';

const textFieldStyles = {
	minWidth: '80%',
	textAlign: 'left',
	color: blueGrey[900],
	marginTop: '1em',
	marginBottom: '1em',

	'& .MuiInput-root': {
		color: blueGrey[900],
	},
	'& .MuiInputLabel-root': {
		color: blueGrey[900],
	},
	'& .MuiInput-root:after': {
		borderColor: blueGrey[900],
	},
};

export default function ContactAccordion({
	originalAccount,
	editingAccount,
	contact,
	index,
	submit,
	saving,
	setSaving,
	handleContactChange,
	users,
	handleContactNumberChange,
	handleDepartmentChange,
	departments,
}) {
	const dispatch = useDispatch();
	const contactNumbers = useSelector(selectContactNumbers);
	const [contactModal, setContactModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [newContactNumber, setNewContactNumber] = useState(null);
	const [deleteContactNumber, setDeleteContactNumber] = useState(null);
	const accordionGridHeaderStyles = (index) => {
		return {
			//width: '20%',
			//flexShrink: 0,
			fontWeight: 'bold',
			//color: color,
			textAlign: 'center',
			color: `${
				_.isEqual(
					originalAccount.Contacts[index],
					editingAccount.Contacts[index]
				)
					? blueGrey[800]
					: orange[800]
			}`,
		};
	};

	function handleContactModal() {
		if (!contactModal) {
			setNewContactNumber({
				id: `new-${crypto.randomUUID()}`,
				Type: 'Choose Type',
				Number: '',
			});
			setContactModal(true);
		} else {
			setNewContactNumber(null);
			setContactModal(false);
		}
	}

	function handleDeleteModal(contactNumber) {
		setDeleteContactNumber(contactNumber);
		setDeleteModal(true);
	}

	function confirmDeleteContactNumber(e) {
		e.preventDefault();
		setSaving(true);
		dispatch(deleteContactNumberAsync(contact._id, deleteContactNumber));

		setDeleteContactNumber(null);
		setDeleteModal(false);
		setSaving(false);
	}

	function saveNewContactNumber(e) {
		e.preventDefault();

		// Check for errors
		if (newContactNumber && newContactNumber.Number === 'Choose Type') {
			dispatch(
				createAlertAsync({
					message: 'Contact Number Type Not Selected',
					severity: 'error',
					autoDismiss: true,
				})
			);
		}

		if (newContactNumber && !matchIsValidTel(newContactNumber.Number)) {
			dispatch(
				createAlertAsync({
					message: 'Contact Number Not Valid',
					severity: 'error',
					autoDismiss: true,
				})
			);
		}

		setSaving(true);
		dispatch(addAccountContactNumberAsync(contact._id, newContactNumber));
		handleContactModal();
	}

	return (
		<Accordion
			key={contact._id}
			disableGutters={true}
			sx={{
				backgroundColor: blueGrey[50],
				color: contact.IsActive ? blueGrey[50] : blueGrey[50],
				padding: 0,
				boxShadow: `0px 0px 2px .5px ${blueGrey[600]}`,
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Grid
					container
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						alignContent: 'center',
					}}
				>
					<Grid
						sm={3}
						item
						sx={
							{
								//display: 'flex',
								//flexDirection: 'row',
								//alignItems: 'center',
								//alignContent: 'end',
								//textAlign: 'right',
							}
						}
					>
						<Typography
							sx={accordionGridHeaderStyles(index)}
						>{`${contact.FirstName} ${contact.LastName}`}</Typography>
					</Grid>
					<Grid
						sm={2}
						item
						sx={
							{
								// display: 'flex',
								// alignItems: 'center',
								// alignContent: 'center',
								// textAlign: 'center',
							}
						}
					>
						<Typography sx={accordionGridHeaderStyles(index)}>
							{contact.Title}
						</Typography>
					</Grid>
					<Grid
						sm={2}
						item
						sx={
							{
								// display: 'flex',
								// alignItems: 'center',
								// alignContent: 'center',
								// textAlign: 'center',
							}
						}
					>
						<Typography sx={accordionGridHeaderStyles(index)}>
							{contact.Department.join(', ')}
						</Typography>
					</Grid>
					<Grid
						sm={3}
						item
						sx={
							{
								// display: 'flex',
								// alignItems: 'center',
								// alignContent: 'center',
								// textAlign: 'center',
							}
						}
					>
						<Typography sx={accordionGridHeaderStyles(index)}>
							{contact.Email}
						</Typography>
					</Grid>
					<Grid
						sm={2}
						item
						sx={
							{
								// display: 'flex',
								// alignItems: 'center',
								// alignContent: 'center',
								// textAlign: 'center',
							}
						}
					>
						{contact.IsActive ? (
							<Box
								sx={{
									marginLeft: 8,
									width: '60%',
									height: 25,
									textAlign: 'center',
									backgroundColor: green[500],
								}}
							>
								Active
							</Box>
						) : (
							<Box
								sx={{
									marginLeft: 8,
									width: '60%',
									height: 25,
									textAlign: 'center',
									backgroundColor: red[500],
								}}
							>
								Inactive
							</Box>
						)}
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>
				<Box
					component='form'
					onSubmit={(e) => submit(e, contact)}
					sx={{
						margin: 0,
						marginTop: 0,
						//backgroundColor: blueGrey[50],
					}}
				>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={10}
							sx={gridItemStyles}
							item
						></Grid>
						<Grid
							sm={2}
							sx={gridItemStyles}
							item
						>
							{saving ? (
								<CircularProgress color='warning' />
							) : (
								<IconButton
									type='submit'
									style={{ border: 'none' }}
								>
									<SaveIcon
										style={{
											color: `${
												_.isEqual(
													originalAccount.Contacts[index],
													editingAccount.Contacts[index]
												)
													? blueGrey[600]
													: orange[800]
											}`,
											fontSize: '1.5em',
											cursor: 'pointer',
										}}
									/>
								</IconButton>
							)}
						</Grid>
					</Grid>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<TextField
								key='IsActive'
								select
								variant='standard'
								label='Contact Status'
								value={contact.IsActive}
								name='IsActive'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							>
								<MenuItem
									key={'IsActive'}
									value={true}
								>
									Active
								</MenuItem>
								<MenuItem
									key={'InActive'}
									value={false}
								>
									InActive
								</MenuItem>
							</TextField>
							<TextField
								key='Salutation'
								variant='standard'
								label='Salutation'
								value={contact.Salutation}
								name='Salutation'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='FirstName'
								required
								variant='standard'
								label='FirstName'
								value={contact.FirstName}
								name='FirstName'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='LastName'
								required
								variant='standard'
								label='LastName'
								value={contact.LastName}
								name='LastName'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='Title'
								required
								variant='standard'
								label='Title'
								value={contact.Title}
								name='Title'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='Territory'
								variant='standard'
								label='Territory'
								value={contact.Territory}
								name='Territory'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
						</Grid>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<TextField
								key='Owner'
								select
								required
								variant='standard'
								label='Owner'
								value={contact.Owner}
								name='Owner'
								onChange={(e) => handleContactChange(e, contact._id)}
								color='secondary'
								sx={textFieldStyles}
							>
								{users &&
									users.map((user) => (
										<MenuItem
											key={user._id}
											value={user._id}
											disabled={!user.IsActive}
											sx={{
												display: `${
													user.IsActive || contact.Owner === user._id
														? 'block'
														: 'none'
												}`,
											}}
										>{`${user.FirstName} ${user.LastName}`}</MenuItem>
									))}
							</TextField>
							<FormControl sx={textFieldStyles}>
								<InputLabel sx={{ left: -14 }}>Department</InputLabel>
								<Select
									name='Department'
									multiple
									value={contact.Department}
									onChange={(e) => handleDepartmentChange(e, contact._id)}
									input={<Input label='Department'>Department</Input>}
									renderValue={(selected) => selected.join(', ')}
									MenuProps={{
										PaperProps: {
											style: {
												width: '20%',
												maxWidth: '20%',
											},
										},
									}}
									sx={textFieldStyles}
								>
									{departments.map((department) => (
										<MenuItem
											key={department._id}
											value={department.Name}
										>
											<Checkbox
												checked={
													contact.Department.indexOf(department.Name) > -1
												}
											/>
											<ListItemText primary={department.Name} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<TextField
								key='Email'
								required
								variant='standard'
								type='email'
								label='Email'
								value={contact.Email}
								name='Email'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<Grid
								container
								sx={gridStyles}
							>
								<Grid
									item
									sm={10}
									sx={gridItemStyles}
								>
									<Typography sx={{ textAlign: 'left', width: '60%' }}>
										Contact Numbers:
									</Typography>
								</Grid>
								<Grid
									item
									sm={1}
									sx={gridItemStyles}
								>
									<AddCircle onClick={handleContactModal} />
								</Grid>
							</Grid>
							{contact.ContactNumber &&
								contact.ContactNumber.map((contactNumber) => (
									<Grid
										container
										key={contactNumber._id}
										sx={gridStyles}
									>
										<Grid
											item
											xs={10}
											sx={gridItemStyles}
										>
											{contactNumbers &&
											contactNumbers.find((x) => x.Name === contactNumber.Type)
												.ValidNumber ? (
												<MuiTelInput
													key={contactNumber._id}
													type='phone'
													variant='standard'
													label={contactNumber.Type}
													value={contactNumber.Number}
													name={contactNumber._id}
													onChange={(e) =>
														handleContactNumberChange(
															e,
															contact._id,
															contactNumber._id,
															contactNumber.Type
														)
													}
													forceCallingCode={true}
													error={!isValidPhoneNumber}
													sx={textFieldStyles}
												/>
											) : (
												<TextField
													key={contactNumber._id}
													required
													variant='standard'
													type='text'
													label={contactNumber.Type}
													value={contactNumber.Number}
													name={contactNumber._id}
													onChange={(e) =>
														handleContactNumberChange(
															e,
															contact._id,
															contactNumber._id,
															contactNumber.Type
														)
													}
													sx={textFieldStyles}
												/>
											)}
										</Grid>
										<Grid
											item
											xs={1}
											sx={{
												display: 'flex',
												alignItems: 'center',
												alignContent: 'center',
											}}
										>
											<DeleteForever
												onClick={() => handleDeleteModal(contactNumber)}
											/>
										</Grid>
									</Grid>
								))}
						</Grid>
					</Grid>
				</Box>
				<Box
					sx={{
						margin: 0,
						marginTop: 6,
						textAlign: 'left',
						marginLeft: 7,
						fontWeight: 'bold',
						//backgroundColor: blueGrey[50],
					}}
				>
					Address:
				</Box>
				<Box sx={{ margin: 0, marginTop: 0 }}>
					<Grid
						container
						sx={gridStyles}
					>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<TextField
								key='Address1'
								variant='standard'
								label='Address1'
								value={contact.Address1}
								name='Address1'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='Address2'
								variant='standard'
								label='Address2'
								value={contact.Address2}
								name='Address2'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='City'
								variant='standard'
								label='City'
								value={contact.City}
								name='City'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='State'
								variant='standard'
								label='State'
								value={contact.State}
								name='State'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='PostalCode'
								variant='standard'
								label='PostalCode'
								value={contact.PostalCode}
								name='PostalCode'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<TextField
								key='Country'
								variant='standard'
								label='Country'
								value={contact.Country}
								name='Country'
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
						</Grid>
						<Grid
							sm={6}
							sx={gridItemStyles}
							item
						>
							<TextField
								key='Notes'
								variant='outlined'
								label='Notes'
								value={contact.Notes}
								name='Notes'
								multiline
								rows={18}
								onChange={(e) => handleContactChange(e, contact._id)}
								sx={textFieldStyles}
							/>
							<Box>
								<Typography
									sx={{ marginLeft: 6, marginRight: 6, textAlign: 'left' }}
								>
									These are the old Phone & Mobile. They are disabled, but I'm
									leaving them right here for now.
								</Typography>
								<MuiTelInput
									disabled
									key='Phone'
									type='phone'
									variant='standard'
									label='Phone'
									value={contact.Phone}
									name='Phone'
									onChange={(e) => handleContactChange(e, contact._id, 'Phone')}
									forceCallingCode={true}
									error={!isValidPhoneNumber}
									sx={textFieldStyles}
								/>
								<MuiTelInput
									disabled
									key='MobilePhone'
									type='phone'
									required
									variant='standard'
									label='Mobile Phone'
									value={contact.MobilePhone}
									name='MobilePhone'
									onChange={(e) =>
										handleContactChange(e, contact._id, 'MobilePhone')
									}
									forceCallingCode={true}
									error={!isValidPhoneNumber}
									sx={textFieldStyles}
								/>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<CreatedModified account={contact} />
			</AccordionDetails>
			<Modal open={contactModal}>
				<Box
					sx={style}
					component='form'
					onSubmit={saveNewContactNumber}
				>
					{newContactNumber && (
						<Box sx={{ textAlign: 'center' }}>
							<FormControl sx={textFieldStyles}>
								<InputLabel sx={{ left: -14 }}>Contact Number Type:</InputLabel>
								<Select
									required
									name='ContactNumberType'
									value={newContactNumber && newContactNumber.Type}
									input={
										<Input label='Contact Number Type'>
											Contact Number Type
										</Input>
									}
									MenuProps={{
										PaperProps: {
											style: {
												width: '20%',
												maxWidth: '20%',
											},
										},
									}}
									onChange={(e) =>
										setNewContactNumber({
											...newContactNumber,
											Type: e.target.value,
										})
									}
									sx={textFieldStyles}
								>
									<MenuItem
										disabled
										value={'Choose Type'}
									>
										Choose Type
									</MenuItem>
									{contactNumbers.map((contactNumber) => (
										<MenuItem
											key={contactNumber._id}
											value={contactNumber.Name}
										>
											{contactNumber.Name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{newContactNumber.Type !== 'Choose Type' && (
								<>
									{contactNumbers.find((x) => x.Name === newContactNumber.Type)
										.ValidNumber ? (
										<MuiTelInput
											required
											key={newContactNumber.id}
											type='phone'
											variant='standard'
											label={`${newContactNumber.Type} Number:`}
											value={newContactNumber.Number}
											name={newContactNumber.id}
											onChange={(e) =>
												setNewContactNumber({
													...newContactNumber,
													Number: e,
												})
											}
											forceCallingCode={true}
											error={!isValidPhoneNumber}
											sx={textFieldStyles}
										/>
									) : (
										<TextField
											required
											key={newContactNumber.id}
											variant='standard'
											type='text'
											label={`${newContactNumber.Type} Number:`}
											value={newContactNumber.Number}
											name={newContactNumber.id}
											onChange={(e) =>
												setNewContactNumber({
													...newContactNumber,
													Number: e.target.value,
												})
											}
											sx={textFieldStyles}
										/>
									)}
								</>
							)}
						</Box>
					)}
					<Box sx={{ marginTop: 8 }}>
						<Grid
							container
							sx={gridStyles}
						>
							<Grid
								sm={6}
								sx={gridItemStyles}
								item
							>
								<Button
									variant='contained'
									onClick={() => handleContactModal()}
									sx={{
										backgroundColor: blueGrey[500],

										'&:hover': { backgroundColor: blueGrey[600] },
									}}
								>
									Cancel
								</Button>
							</Grid>

							<Grid
								sm={6}
								sx={gridItemStyles}
								item
							>
								<Button
									disabled={
										newContactNumber &&
										(newContactNumber.Type === 'Choose Type' ||
											newContactNumber.Number === '')
									}
									variant='contained'
									type='submit'
									sx={{
										backgroundColor: blueGrey[500],
										'&:hover': { backgroundColor: blueGrey[600] },
									}}
								>
									Save
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Modal>
			<Modal open={deleteModal}>
				<Box
					sx={style}
					component='form'
					onSubmit={confirmDeleteContactNumber}
				>
					<Box sx={{ marginTop: 4, textAlign: 'center' }}>
						<Typography>
							Are you sure you want to delete <br />
							the selected Contact Number?
						</Typography>
					</Box>
					<Box sx={{ marginTop: 4, textAlign: 'center' }}>
						<Typography>
							Type: {deleteContactNumber && deleteContactNumber.Type}
						</Typography>
						<Typography>
							Number: {deleteContactNumber && deleteContactNumber.Number}
						</Typography>
					</Box>
					<Box sx={{ marginTop: 4 }}>
						<Grid
							container
							sx={gridStyles}
						>
							<Grid
								sm={6}
								sx={gridItemStyles}
								item
							>
								<Button
									variant='contained'
									onClick={() => setDeleteModal(false)}
									sx={{
										backgroundColor: blueGrey[500],

										'&:hover': { backgroundColor: blueGrey[600] },
									}}
								>
									Cancel
								</Button>
							</Grid>

							<Grid
								sm={6}
								sx={gridItemStyles}
								item
							>
								<Button
									variant='contained'
									type='submit'
									sx={{
										backgroundColor: blueGrey[500],
										'&:hover': { backgroundColor: blueGrey[600] },
									}}
								>
									Delete
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Modal>
		</Accordion>
	);
}

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};
