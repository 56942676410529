import React, { useEffect } from 'react';
import { blueGrey } from '@mui/material/colors';
import { useParams } from 'react-router-dom';
import Departments from '../components/admin/Departments';
import ContactNumbers from '../components/admin/ContactNumbers';
import Currencies from '../components/admin/Currencies';
import Genres from '../components/admin/Genres';
import Formats from '../components/admin/Formats';
import Definitions from '../components/admin/Definitions';
import Languages from '../components/admin/Languages';
import Territories from '../components/admin/Territories';
import LicensedMedias from '../components/admin/LicensedMedias';
import Users from '../components/admin/Users';
import Roles from '../components/admin/Roles';
import { Box } from '@mui/system';

function Admin({ setParams, location }) {
	let params = useParams();

	useEffect(() => {
		setParams(params);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	function renderContent() {
		if (params.sub === 'departments') {
			return <Departments />;
		} else if (params.sub === 'contactnumbers') {
			return <ContactNumbers />;
		} else if (params.sub === 'currencies') {
			return <Currencies />;
		} else if (params.sub === 'territories') {
			return <Territories />;
		} else if (params.sub === 'users') {
			return <Users />;
		} else if (params.sub === 'genres') {
			return <Genres />;
		} else if (params.sub === 'formats') {
			return <Formats />;
		} else if (params.sub === 'roles') {
			return <Roles />;
		} else if (params.sub === 'definitions') {
			return <Definitions />;
		} else if (params.sub === 'languages') {
			return <Languages />;
		} else if (params.sub === 'licensedmedia') {
			return <LicensedMedias />;
		}
	}

	return (
		<Box
			sx={{
				padding: '2em',
				paddingTop: '1em',
				textAlign: 'center',
				backgroundColor: blueGrey[500],
				color: 'white',
				height: '90vh',
				overflowY: 'auto',
				borderRadius: '8px',
				boxShadow: `0px 5px 10px 5px${blueGrey[800]}`,
			}}
		>
			{renderContent()}
		</Box>
	);
}

export default Admin;
