import {
	combineReducers,
	configureStore,
	getDefaultMiddleware,
} from '@reduxjs/toolkit';
import {
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'reduxjs-toolkit-persist';
//import storage from 'reduxjs-toolkit-persist/lib/storage';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session';
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1';
import accountReducer from './accountSlice';
import drawerReducer from './drawerSlice';
import tabsReducer from './tabsSlice';
import authReducer from './authSlice';
import alertsReducer from './alertsSlice';
import roleReducer from './roleSlice';
import departmentReducer from './departmentSlice';
import contactNumberReducer from './contactNumberSlice';
import currencyReducer from './currencySlice';
import genreReducer from './genreSlice';
import formatReducer from './formatSlice';
import languageReducer from './languageSlice';
import definitionReducer from './definitionSlice';
import userReducer from './userSlice';
import screenerReducer from './screenerSlice';
import salesReducer from './salesSlice';
import programReducer from './programSlice';
import licensedmediaReducer from './licensedmediaSlice';
import territoryReducer from './territorySlice';
import taskReducer from './taskSlice';
import reportReducer from './reportSlice';

const persistConfig = {
	key: 'lgi',
	storage: storageSession,
	timeout: 1000,
	stateReconciler: autoMergeLevel1,
};

const reducers = combineReducers({
	alerts: alertsReducer,
	auth: authReducer,
	drawer: drawerReducer,
	tabs: tabsReducer,
	accounts: accountReducer,
	role: roleReducer,
	department: departmentReducer,
	contactnumber: contactNumberReducer,
	currency: currencyReducer,
	genre: genreReducer,
	format: formatReducer,
	definition: definitionReducer,
	language: languageReducer,
	user: userReducer,
	sales: salesReducer,
	screeners: screenerReducer,
	programs: programReducer,
	licensedmedia: licensedmediaReducer,
	territory: territoryReducer,
	task: taskReducer,
	reports: reportReducer,
});

const _persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
	reducer: _persistedReducer,
	middleware: getDefaultMiddleware({
		serializableCheck: {
			// ignore persistance actions
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			ignoredPaths: ['screeners.screeners'],
		},
	}),
});
